import React, { useState, useEffect, useRef } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css'; // Default styling
import { useLoading } from './LoadingContext';
import ReCAPTCHA from "react-google-recaptcha";

window.onRecaptchaLoad = () => {
  document.dispatchEvent(new CustomEvent('recaptchaLoaded'));
};


function BookingCalendar() {
  const [bookings, setBookings] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [comment, setComment] = useState('');
  const [successfulBooking, setSuccessfulBooking] = useState(false);
  const { setLoading } = useLoading();
  const recaptchaRef = useRef(null);
  const [captchaValidated, setCaptchaValidated] = useState(false);


  const authHeader = 'Basic ' + btoa('TaylorHillier - WTH:Rmg4 W48D 3J2O R7eb FYYK Grxk');
  useEffect(() => {
  fetch(`https://www.townhouseatwhistler.com/wordpress/index.php/wp-json/sbm/v1/bookings`, {
    headers: {
      'Content-Type': 'application/json',
      // Include the Basic Auth header
      'Authorization': authHeader,
  },
  })
    .then(response => response.json())
    .then(data => {
      const formattedBookings = data.map(booking => {
        const startDate = new Date(booking.start);
        const endDate = new Date(booking.end);
        //console.log(startDate, endDate); // Check parsed dates
        return { start: startDate, end: endDate };
      });
      setBookings(formattedBookings);
      setLoading(false);
    });
}, []);

  // Function to determine if a day is booked
  function isBooked(date) {
    return bookings.some(booking => date >= booking.start && date <= booking.end);
  }

  function onRecaptchaChange(value) {
    console.log("Captcha value:", value);  // Log the reCAPTCHA response value
    if (value) {  // Check if the reCAPTCHA returned a response
        setCaptchaValidated(true);
    } else {
        setCaptchaValidated(false);
    }
}

 
  // Tile content function to customize calendar tiles
  function tileContent({ date, view }) {
    if (view === 'month' && isBooked(date)) {
      return <div className='booked-date'></div>; // Customize as needed
    } else if (view === 'month' && !isBooked(date)) {
      return <div className='open-date'></div>
    }
  }

  function submitBookingRequest(event) {
    event.preventDefault();
    const captchaValue = recaptchaRef.current.getValue();
    if (!captchaValue) {
        alert('Please verify you are not a robot.');
        return;
    }

    const bookingData = {
        name,
        email,
        startDate,
        endDate,
        comment,
        'g-recaptcha-response': captchaValue,
    };

    fetch(`https://www.townhouseatwhistler.com/wordpress/index.php/wp-json/sbm/v1/booking-request`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(bookingData),
    })
    .then(response => response.json())
    .then(data => {
        if(data.success) {
            setSuccessfulBooking(true);
        } else {
            alert("There was a problem with your booking request. Please try again.");
        }
        recaptchaRef.current.reset();
        setCaptchaValidated(false);  // Reset reCAPTCHA validation state
    })
    .catch(error => {
        console.error('Error:', error);
    });
}

function onRecaptchaChange(value) {
   // Log the reCAPTCHA response value
  if (value) {  // Check if the reCAPTCHA returned a response
      setCaptchaValidated(true);
  } else {
      setCaptchaValidated(false);
  }
}


  
    function tileClassName({ date, view }) {
      // Apply the class only for calendar's month view
      if (view === 'month') {
        // Check if the day is booked
        if (isBooked(date)) {
          return 'booked-date'; // Return the class for booked dates
        }else{
          return 'open-date';
        }
      }
      // Return nothing if not booked or not in month view
    }

    let inputStyle = 'm-2 p-[1rem] ';
    let bookedLegendStyle = 'h-[20px] w-[20px] inline-block m-[10px] bg-[#ff6347]';
    let availableLegendStyle = 'h-[20px] w-[20px] inline-block m-[10px] bg-[#3cb371]';

  return (
    <div className='grid lg:grid-cols-2 max-w-[1260px] m-auto'>
      <div className='flex justify-center items-center flex-col'>
        <Calendar tileClassName={tileClassName} tileContent={tileContent}/>
          <div className='legend-container flex-col flex m-8'> 
                  <ul className='legend'>
                    <li className='flex items-center justify-center font-semibold'><span className={bookedLegendStyle}></span>Booked</li> 
                    <li className='flex items-center justify-center font-semibold'><span className={availableLegendStyle}></span>Available</li>
                  </ul>
          </div>
      </div>
      {successfulBooking? (
        <div className='flex flex-col justify-center items-center text-xl p-16 bg-[#232323] text-white'>
          <p>Thank you for your booking!</p>
          <p>We will be in contact with you shortly.</p>
        </div>
      ) :
      (
        <div>
          <div className='booking-form py-16 px-4 bg-gray-300 mb-8'>
            <h2 className='text-center'>Request to Book Here!</h2>
            <form onSubmit={submitBookingRequest} className='booking-form py-16 px-4 bg-gray-300 mb-8 flex flex-col' aria-label="Booking Form">
              <label htmlFor="name" className="sr-only">Your Name</label>
              <input id="name" className={inputStyle} type="text" value={name} onChange={e => setName(e.target.value)} placeholder="Your Name" required />
              
              <label htmlFor="email" className="sr-only">Your Email</label>
              <input id="email" className={inputStyle} type="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="Your Email" required />
              
              <label htmlFor="startDate" className="sr-only">Start Date</label>
              <input id="startDate" className={inputStyle} type="date" value={startDate} onChange={e => setStartDate(e.target.value)} placeholder="Start Date" required />
              
              <label htmlFor="endDate" className="sr-only">End Date</label>
              <input id="endDate" className={inputStyle} type="date" value={endDate} onChange={e => setEndDate(e.target.value)} placeholder="End Date" required />
              
              <label htmlFor="comment" className="sr-only">Comments</label>
              <textarea id="comment" className={`${inputStyle} resize-none`} value={comment} onChange={e => setComment(e.target.value)} placeholder="Add any comments here" rows="4"></textarea>
              
              <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="6LfKWMYpAAAAAGCTkd4KYohOOIuiWWvotkh0aTD0"
              onChange={onRecaptchaChange}
            />
              
              <button type="submit" disabled={!captchaValidated} className={`${inputStyle} form-button bg-[#1291b1] text-white font-bold border-transparent border-solid border-[1px] ${captchaValidated ? 'hover:border-[#1291b1] hover:bg-white' : 'cursor-not-allowed bg-gray-400'}`}>
                Submit Booking Request
              </button>
              {successfulBooking && <div aria-live="polite">Thank you for your booking! We will be in contact with you shortly.</div>}
            </form>
          </div>
      </div>
      )}
    </div>
  );
}

export default BookingCalendar;
