import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom"; // Import useLocation
import { useLoading } from "./LoadingContext";
import Loading from "./Loading";

function Banner({ apiUrl, page }) {
  const [isLoaded, setLoadStatus] = useState(false);
  const [bannerImages, setImages] = useState([]);
  const { setLoading } = useLoading();
  const location = useLocation(); // Use useLocation to access the current route

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}pages/${page}?acf_format=standard`);
        if (!response.ok) {
          throw new Error('Failed to fetch');
        }
        const data = await response.json();
        setImages(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Indicate the end of the loading process
      }
    };
    fetchData();
  }, [apiUrl, setLoading]);

  if (!bannerImages || !bannerImages.acf || !bannerImages.acf.banner_images) {
    return <Loading />;
  }

  // Conditionally set the class based on the route
  const bannerClass = location.pathname === '/' ? "h-screen top-0 left-0 mb-4" : "top-24 h-96";

  return (
    <div className={`Banner flex z-[-10] brightness-[30%] ${bannerClass} absolute w-full left-0 `}>
      <img className="object-cover w-full h-full" src={bannerImages.acf.banner_images?.banner_image_1.url} />
    </div>
  );
}

export default Banner;
