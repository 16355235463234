import { useState, useEffect } from "react";
import { GoogleMap, useLoadScript, MarkerF } from '@react-google-maps/api';
import { useLoading } from "./LoadingContext";
import Banner from "./Banner";

const libraries = ['places'];

const mapContainerStyle = {
 
  height: '50vh',
};
const center = {
  lat: 50.11304934862506, // default latitude
  lng: -122.94913815520482, // default longitude
};

function GettingAroundPage({apiUrl}){
  const [aroundFields, setAroundFields] = useState(null);
    const { setLoading, isLoading } = useLoading();
  
  useEffect(() => {
    setLoading(true); // Indicate the start of a loading process
    const fetchData = async () => {
        try {
            const response = await fetch(`${apiUrl}pages/44`);
            if (!response.ok) {
                throw new Error('Failed to fetch');
            }
            const data = await response.json();
            setAroundFields(data);
            //console.log(data); // Right after setAroundFields(data);
        } catch (error) {
            console.error("Error fetching data:", error);
            // Optionally handle error state here
        } finally {
            setLoading(false); // Indicate the end of the loading process
        }
        };
        fetchData();
    }, [apiUrl, setLoading]); // Dependency array

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: 'AIzaSyDRMKcC8SYjuuQiazO9NKgTPFtXDo1rdgk',
        libraries,
      });
    
      if (loadError) {
        return <div>Error loading maps</div>;
      }
    
      if (!isLoaded) {
        return <div>Loading maps</div>;
      }
    
      // Ensure aroundFields.acf and aroundFields.acf.links are defined and iterable
  if (!aroundFields || !aroundFields.acf || !Array.isArray(aroundFields.acf.links)) {
    return null; // or loading indicator
  }
      return (

        <div>
          <Banner apiUrl={apiUrl} page={44}/>
          <div className=" flex flex-col gap-8  text-white px-4 py-20 ">
            <h1 className="text-5xl">{aroundFields.acf.page_title}</h1>
            <h2 className="text-lg">{aroundFields.acf.subtitle}</h2>
          </div>
          <div className="links mx-4 mt-24 min-h-[50vh] flex flex-col justify-center" id="around-links">
            {aroundFields && aroundFields.acf && (
              <>
                <h3 className="py-8 text-4xl text-center">{aroundFields.acf.links_title}</h3>
                <div className="md:grid md:grid-cols-2 lg:grid-cols-3 md:justify-items-center m-auto gap-x-24">
                  {aroundFields.acf.links && aroundFields.acf.links.map((link, index) => (
                    <div key={index} className="md:m-4 py-4 w-full" >
                      <h4 className="text-center">{link.section_title}</h4>
                      <ul className=" list-disc my-8 mx-4 text-center">
                        {link.section_links && link.section_links.map((singleLink, idx) => (
                          <li key={idx} >
                            <a href={singleLink.individual_link} target="_blank" rel="noreferrer" className="around-links no-underline text-[#1291b1] hover:text-[#4905a7] font-bold text-lg">
                              {singleLink.individual_link_title}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
          {!isLoading && 
          <div className="relative">
            <GoogleMap
            mapContainerStyle={mapContainerStyle }
            zoom={10}
            center={center}
            >
            <MarkerF position={center} />
            </GoogleMap>
          </div>} 
        </div>
      );
}

export default GettingAroundPage;