import { useState, useEffect} from 'react';
import Calendar from './Calender'
import { useLoading } from './LoadingContext';
import Accordion from 'react-bootstrap/Accordion';
import 'bootstrap/dist/css/bootstrap.min.css';

function RatesPage({apiUrl}){
    const [rateFields, setRateFields] = useState(null);
    const { setLoading, isLoading } = useLoading();

    useEffect(() => {
        setLoading(true); // Indicate the start of a loading process
        const fetchData = async () => {
            try {
                const response = await fetch(`${apiUrl}pages/16`);
                if (!response.ok) {
                    throw new Error('Failed to fetch');
                }
                const data = await response.json();
                setRateFields(data);
            } catch (error) {
                console.error("Error fetching data:", error);
                // Optionally handle error state here
            } finally {
                setLoading(false); // Indicate the end of the loading process
            }
        };
        fetchData();
    }, [apiUrl, setLoading]); // Dependency array

    return(
        <div>
                {rateFields && rateFields.acf && rateFields.acf.nightly_rates && (
                    <div className='nightly-rates p-8 bg-[#232323] text-white lg:pl-24'>
                        <h1>{rateFields.acf.nightly_rates.title}</h1>
                        <div className='my-4 font-semibold'>
                        {rateFields.acf.nightly_rates.paragraphs.map((item, index) => (
                            <p key={index}>{item.paragraph}</p>
                        ))}
                        </div>
                    </div>
                )}
                { !isLoading && <p className='m-auto p-8 text-center font-bold text-xl'>NOTICE: Calendar is not updated at the moment - please visit our AirBNB or VRBO page to check availability. Thanks.</p>}
                {rateFields && rateFields.acf && rateFields.acf.pricing_and_dates && (
                    <div className='m-auto flex justify-center'>
                        <h1>{rateFields.acf.pricing_and_dates.title}</h1>
                        <div className='m-4 flex-col items-center justify-center md:grid md:grid-cols-2'>
                        {rateFields.acf.pricing_and_dates.time_period.map((item, index) => (
                            <p key={index} className='grid grid-cols-2 divide-x items-center justify-center text-center font-bold'><span className='text-sm p-4'>{item.price_date_pairing.date_range}</span><span className='font-bold'>{item.price_date_pairing.price}</span></p>
                        ))}
                        </div>
                    </div>
                )}
          
            {!isLoading && <Calendar />}
            {rateFields && rateFields.acf && rateFields.acf.policy && rateFields.acf.policy.policies && (
            <Accordion defaultActiveKey="0" flush className='mb-16 max-w-[1260px] mx-auto'>
            {rateFields && rateFields.acf && rateFields.acf.policy && rateFields.acf.policy.policies &&
                rateFields.acf.policy.policies.map((policy, index) => (
                    <Accordion.Item eventKey={`${index}`} key={index}>
                        <Accordion.Header>{policy.title}</Accordion.Header>
                        <Accordion.Body>
                            {policy.info.map((item, idx) => (
                                <p key={idx}>{item.policy_info}</p>
                            ))}
                        </Accordion.Body>
                    </Accordion.Item>
                ))
            }
            </Accordion>
            )}
        </div>
    )
}

export default RatesPage;