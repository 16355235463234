import React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function CTABanner() {
    const [ctaFields, setCtaFields] = useState([]);

    useEffect(() => {
      const fetchData = async () => {
        const response = await fetch(`https://www.townhouseatwhistler.com/wordpress/index.php/wp-json/acf/v3/options/options`);
        if (response.ok) {
          const data = await response.json();
          setCtaFields(data);
        } 
      };
      fetchData();
    }, []);

    if (!ctaFields || !ctaFields.acf || !ctaFields.acf.calls_to_action) return <p role="alert">Error loading CTAs.</p>;
    
    let navLink=' p-[1.5rem] uppercase font-semibold md:p-4 min-h-20 flex items-center justify-center';
    return(
        <section style={{ backgroundImage: `url(${ctaFields.acf.banner_background.url})` }} className="text-center min-h-[10rem] flex-col justify-evenly flex text-white sm:p-16 md:p-24" aria-label="Call to Action Banner">
            <h1 className="m-8 text-4xl sm:w-[50vw] sm:mx-auto">{ctaFields.acf.banner_title}</h1>
            <div className="flex-col flex m-8 gap-8 justify-around md:flex-row md:mx-auto md:max-w-[50vw] md:gap-4">
              {ctaFields.acf.calls_to_action.cta_1 && (
                  <Link to={`/${ctaFields.acf.calls_to_action.cta_1.cta_1_link}`} className={`${navLink} text-white bg-[#234343] hover:bg-transparent border-solid border-[1px] border-transparent hover:border-white sm:w-[50vw] sm:m-auto`} aria-label={`Navigate to ${ctaFields.acf.calls_to_action.cta_1.cta_1_content}`}>{ctaFields.acf.calls_to_action.cta_1.cta_1_content}</Link>
              )}
              {ctaFields.acf.calls_to_action.cta_2 && (
                  <Link to={`/${ctaFields.acf.calls_to_action.cta_2.cta_2_link}`} className={`${navLink} no-underline hover:text-white bg-[#D1D1D1] text-[#232323] hover:bg-transparent border-solid border-[1px] border-transparent hover:border-white sm:w-[50vw] sm:m-auto`} aria-label={`Navigate to ${ctaFields.acf.calls_to_action.cta_2.cta_2_content}`}>{ctaFields.acf.calls_to_action.cta_2.cta_2_content}</Link>
              )}
            </div>
        </section>
    );
}

export default CTABanner;
