import { Link, useNavigate } from "react-router-dom";
import Trademark from "./Trademark";

export default function Footer() {
    const navigate = useNavigate();

    const handleLinkClick = (path) => {
        navigate(path);
        // Focus management
        document.getElementById('main-content').focus(); // Assuming an element with id="main-content" exists as the main content container
    };

    return (
        <footer aria-labelledby="footer-heading">
            <h2 id="footer-heading" className="sr-only">Footer Navigation</h2>
            <div className="md:grid md:grid-cols-3">
                <nav className='text-center bg-[#232323] text-white font-semibold uppercase p-16 footer-ul w-full' aria-label="Footer navigation">
                    <ul>
                        <li tabIndex="0" onClick={() => handleLinkClick("/")} className="hover:cursor-pointer">
                            Home
                        </li>
                        <li tabIndex="0" onClick={() => handleLinkClick("/features")} className="hover:cursor-pointer">
                            Features
                        </li>
                        <li tabIndex="0" onClick={() => handleLinkClick("/photos")} className="hover:cursor-pointer">
                            Photos
                        </li>
                        <li tabIndex="0" onClick={() => handleLinkClick("/gettingaround")} className="hover:cursor-pointer">
                            Getting Around
                        </li>
                        <li tabIndex="0" onClick={() => handleLinkClick("/rates")} className="hover:cursor-pointer">
                            Booking and Contact Info
                        </li>
                    </ul>
                </nav>
                <div id="reviews" className="text-center bg-[#232323] text-white font-semibold uppercase p-16 md:justify-center w-full md:col-span-2">
                    <h3 className="text-lg">See our reviews on AirBnB and VRBO! Then come back here to avoid the fees ;)</h3>
                    <ul className="flex flex-col">
                        <li><a href="https://www.vrbo.com/780613" target="_blank" rel='noreferrer noopener' className="text-[#1291b1] hover:text-white">VRBO</a></li>
                        <li><a href="https://www.airbnb.ca/rooms/9276774" target="_blank" rel='noreferrer noopener' className="text-[#1291b1] hover:text-white">AirBNB</a></li>
                    </ul>
                    <p>For non-booking related inquiries, please contact: <a href="mailto:whistlertownhouse8@gmail.com" className="text-[#1291b1] normal-case">whistlertownhouse8@gmail.com</a></p>
                </div>
            </div>
            <Trademark/>
        </footer>
    );
}
