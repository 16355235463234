import React, { useEffect, useState } from "react";
import Loading from "./Loading";
import Banner from "./Banner";
import { useLoading } from "./LoadingContext"; // Assuming this is correctly set up elsewhere
import { Link } from "react-router-dom";

function HomePage({ apiUrl }) {
    const [homeFields, setHomeFields] = useState(null); // Initialize to null for easier checking
    const { setLoading } = useLoading();

    useEffect(() => {
        setLoading(true); // Indicate the start of a loading process
        const fetchData = async () => {
            try {
                const response = await fetch(`${apiUrl}pages/12`);
                if (!response.ok) {
                    throw new Error('Failed to fetch');
                }
                const data = await response.json();
                setHomeFields(data);
            } catch (error) {
                console.error("Error fetching data:", error);
                // Optionally handle error state here
            } finally {
                setLoading(false); // Indicate the end of the loading process
            }
        };
        fetchData();
    }, [apiUrl, setLoading]); // Dependency array

    // Check if homeFields is null to handle the loading state locally
    if (!homeFields) {
        return <Loading />;
    }

    return (
        <div>
            <section className="intro ">
                <Banner apiUrl={apiUrl} page={12} />
                <h1 className="text-4xl sm:text-5xl max-w-[1200px] m-auto font-bold text-white p-8 h-96 sm:h-80 mb-0 text-center flex justify-center items-center">{homeFields.acf.intro_text.intro_title}</h1>
                <div className="md:p-12 lg:p-24">
                     <div className="m-auto  bg-[#232323] py-12 md:py "> 
                        {/* <h1 className="text-center text-4xl font-bold py-8 text-white">Amenities and Details</h1> */}
                        <ul className="grid grid-cols-1 justify-items-center text-white mx-16 sm:grid sm:grid-cols-2 sm:gap-2 md:grid-cols-3 lg:grid-cols-5 ">
                            {homeFields.acf.number_display.map((item, index) => (
                                <li key={index} className="mx-2 my-1 sm:m-0 text-center w-full bg-[#234343] p-4 h-32 items-center justify-center flex font-bold text-2xl">
                                    <p className="mb-0 text-base"><span className="text-4xl font-bold">{item.number}</span> <br/>{item.feature}</p>
                                </li>
                            ))}
                        </ul>
                         <Link to={`/${homeFields.acf.booking_cta.link}`} className="booking-cta text-2xl font-semibold  text-center flex justify-center items-center p-8 h-24 mx-16 my-4 bg-[#D1D1D1] hover:bg-transparent hover:border-white border-2 border-transparent hover:cursor-pointer hover:text-[#D1D1D1]">{homeFields.acf.booking_cta.title}</Link>
                       
                    </div>
                </div>
            </section>
                <section className="md:p-12 content">
                    <div className=" p-8 mx-auto ">
                        {homeFields.acf.intro_text.intro_paragraphs.split('\r\n').filter(paragraph => paragraph.trim() !== '').map((paragraph, index) => (
                            <p key={index} className="my-8 text-lg font-semibold mx-4">{paragraph}</p>
                        ))}
                    </div>
                </section>
        </div>
    );
}

export default HomePage;
