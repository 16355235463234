import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';

import RatesPage from './Components/Rates';
import PhotosPage from './Components/Photos';
import HomePage from './Components/Home';
import FeaturesPage from './Components/Features';
import GettingAroundPage from './Components/GettingAround';
import Nav from './Components/Nav';
import Footer from './Components/Footer';
import CTABanner from './Components/CTABanner';
import { useLoading } from './Components/LoadingContext';

function App() {
  const location = useLocation();
  const { isLoading } = useLoading();

  const wpApiUrl = 'https://www.townhouseatwhistler.com/wordpress/index.php/wp-json/wp/v2/';
  return (
          <div className='app-container'>
            { !isLoading &&  <Nav />}
            <main className='content-container max-w-[75rem] m-auto pt-24 ' id='top-of-content' tabIndex={-1}>
              <Routes>
                <Route path='/' element={<HomePage apiUrl={wpApiUrl}/>}/>
                <Route path='/features' element={<FeaturesPage  apiUrl={wpApiUrl}/>}/>
                <Route path='/photos' element={<PhotosPage  apiUrl={wpApiUrl}/>}/>
                <Route path='/gettingaround' element={<GettingAroundPage  apiUrl={wpApiUrl}/>}/>
                <Route path='/rates' element={<RatesPage  apiUrl={wpApiUrl}/>}/>
              </Routes>
              {location.pathname !== '/rates' && !isLoading && <CTABanner/>}
            </main>
            {!isLoading && <Footer/>}
          </div>
  
  );
}

export default App;
