import Banner from "./Banner";
import React, {useEffect, useState} from "react";
import Loading from "./Loading";
import { useLoading } from "./LoadingContext";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

function FeaturesPage({apiUrl}){

    const [features, setFeatures] = useState(null);
    const { setLoading } = useLoading();
    
    useEffect(() => {
        setLoading(true); // Indicate the start of a loading process
        const fetchData = async () => {
            try {
                const response = await fetch(`${apiUrl}pages/14`);
                if (!response.ok) {
                    throw new Error('Failed to fetch');
                }
                const data = await response.json();
                setFeatures(data);
            } catch (error) {
                console.error("Error fetching data:", error);
                // Optionally handle error state here
            } finally {
                setLoading(false); // Indicate the end of the loading process
            }
        };
        fetchData();
    }, [apiUrl, setLoading]); // Dependency array

    let featureCard = 'p-8 bg-[#232323] text-white my-1';
      if (!features || !features.acf || !features.acf.features_per_floor || !features.acf.features_text || !features.acf.other_features || !features.acf.amenities_group.amenities_tab) return ; // Error handling or fallback content

    return(
        <div className="features-content">
            <Banner apiUrl={apiUrl} page={14}/>
            <section aria-labelledby="features-heading" className="h-96 flex flex-col justify-center mx-8">
                <h1 id="features-heading" className="text-white text-5xl">Features</h1>
                <p className="text-white text-2xl">Here's what you would have to look forward to during your stay!</p>
            </section>
            <section id="features">
                <h2 className="uppercase text-center py-20 bg-[#232323] text-white"> Floor Layout</h2>
                <div className="floors grid md:grid-cols-2 lg:grid-cols-4 mx-4 py-20 text-center gap-4">
                {features.acf.features_per_floor.map((featureSet, index) => (
                    <div key={index} className="my-4">
                        {featureSet.floors.map((floor, floorIndex) => (
                            <div key={floorIndex} >
                                <h3 className="uppercase text-[#1291b1] font-bold">{floor.floor_title}</h3>
                                <ul className="font-semibold">
                                    {floor.features_list.map((feature, featureIndex) => (
                                        <li key={featureIndex} className="m-4">{feature.single_features}</li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                ))}
                </div>
                {/* Conditional rendering to check if features_text and its properties exist */}
                {features.acf.features_text && (
                    <div>
                        <h4 className="my-4 font-bold text-center text-3xl bg-[#232323] text-white py-20 px-4">{features.acf.features_text.content_title}</h4>
                        <div className="lg:grid-cols-2 lg:grid md:p-16 justify-items-center">
                            {features.acf.features_text.content_blocks?.map((block, index) => (
                                <p key={index} className="font-semibold max-w-[75ch] mx-8 md:mx-auto lg:mx-8 ">{block.paragraph}</p>
                            ))}
                        </div>
                    </div>
                )}
                {/* Similar checks for bed_bath_kitchen */}
                {features.acf.bed_bath_kitchen && (
                    <div className="grid grid-cols-1 md:grid-cols-3 py-20 text-center">
                        <div className={featureCard}>
                            <img src={features.acf.bed_bath_kitchen.bedrooms.image.url}/>
                            <h5 className="m-4">{features.acf.bed_bath_kitchen.bedrooms.bedroom_title}</h5>
                                <ul >
                                    {features.acf.bed_bath_kitchen.bedrooms.bedroom_field.map((bedroom, index) => (
                                        <li key={index}>{bedroom.bedroom_name}: {bedroom.bedroom_info}</li>
                                    ))}
                                </ul>
                        </div>
                        <div className={featureCard}>
                            <img src={features.acf.bed_bath_kitchen.bathrooms.image.url}/>
                            <h5 className="m-4">{features.acf.bed_bath_kitchen.bathrooms.bathroom_title}</h5>
                            <p>{features.acf.bed_bath_kitchen.bathrooms.bathroom_text}</p>
                        </div>
                        <div className={featureCard}>
                            <img src={features.acf.bed_bath_kitchen.kitchen.image.url}/>
                            <h5 className="m-4">{features.acf.bed_bath_kitchen.kitchen.kitchen_title}</h5>
                            <p>{features.acf.bed_bath_kitchen.kitchen.kitchen_info}</p>
                        </div>
                    </div>
                )}
                <div className="other-features md:flex ">
                    <h6 className="md:min-w-[25vw] text-3xl bg-[#232323] text-white flex items-center justify-center p-8"> {features.acf.other_features}</h6>
                    {features.acf && (
                        <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 m-8 gap-x-8">
                            {features.acf.list_of_features.map((feature, index) => (
                                <li key={index} className="other-feature"><p>{feature.feature}</p></li>
                            ))}
                        </ul>
                    )}
                </div>
            </section>
            <section id="amenities-container">
                <div className="amenities py-16">
                    <h1 className="px-4 py-8 text-center">{features.acf.amenities_group.amenities_title}</h1>
                    <Tabs >
                        <TabList aria-label="Features Tabs">
                        {features.acf.amenities_group.amenities_tab.map((tab, index) => (
                            <Tab key={index} >{tab.tab_title}</Tab>
                        ))}
                        </TabList>

                        {features.acf.amenities_group.amenities_tab.map((tab, index) => (
                        <TabPanel key={index} className='m-8'>
                            <ul className="grid sm:grid-cols-2 md:grid-cols-3 gap-2 justify-items-center text-center min-h-[500px] md:min-h-[250px] lg:min-h-[200px]">
                                {tab.tab_items.map((item, itemIndex) => (
                                    <li key={itemIndex}>{item.tab_item}</li>
                                ))}
                            </ul>
                        </TabPanel>
                        ))}
                    </Tabs>
                </div>
            </section>
        </div>
    )
}

export default FeaturesPage;