import React, { useState, useEffect, useMemo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Hamburger from 'hamburger-react';
import townhouseLogo from '../Media/townhouse-at-whistler-logo-white-300x185.png';
import darkTownHouseLogo from '../Media/townhouse-at-whsitler-logo.png';

function Nav() {
  const [isOpen, setOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
      document.body.style.overflow = isOpen ? 'hidden' : 'unset';
  }, [isOpen]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const headerClass = useMemo(() => {
    return `w-full z-50 top-0 left-0 fixed ${location.pathname !== '/' || isScrolled ? 'bg-white' : 'bg-transparent'}`;
  }, [location.pathname, isScrolled]);

  const navClasses = useMemo(() => {
    return `Navbar h-24 flex justify-between items-center transition-colors duration-300 ease-in-out lg:max-w-[75rem] lg:m-auto ${
      isScrolled || location.pathname !== '/' ? ' text-black' : 'bg-transparent text-white'
    }`;
  }, [location.pathname, isScrolled]);

  const mobileNavClasses = useMemo(() => {
    return `list-none flex flex-col lg:flex-row absolute lg:relative lg:mt-0 top-0 right-0 lg:transform-none lg:max-w-[75rem] lg:mx-auto transform ${isOpen ? 'translate-x-0 h-screen w-screen inset-0 px-4 py-24' : 'px-4 py-24 translate-x-full h-screen lg:h-auto inset-0'} gap-2 w-full text-3xl lg:text-lg lg:w-auto h-24 z-40 transition-transform duration-300 ease-in-out lg:items-center lg:py-0 font-semibold ${isScrolled || location.pathname !== '/' ? 'bg-white  text-black' : 'bg-[#232323] lg:bg-transparent text-white'}
    `;
  }, [isOpen, location.pathname, isScrolled]);
  

  const logoSrc = isScrolled || location.pathname !== '/' ? darkTownHouseLogo : townhouseLogo;

  const linkClass = (isActive) => `
  header-link ml-4 
  ${isActive ? 'active' : ''} 
  ${location.pathname !== '/' || isScrolled ? 'text-black hover:text-gray-700' : 'text-white hover:text-gray-300'}
`;

  return (
    <header className={headerClass}>
      <nav className={navClasses}>
        <div className='z-10 ml-4 lg:max-w-[75rem] lg:m-auto'>
          <NavLink to="/"><img height={48} width={144} src={logoSrc} alt="Townhouse at Whistler Logo" /></NavLink>
        </div>
        <div className='lg:hidden mr-4 z-50 lg:max-w-[75rem] lg:m-auto'>
          <Hamburger toggled={isOpen} toggle={setOpen} color={isScrolled || location.pathname !== '/' ? '#000' : '#fff'} />
        </div>
        
        <ul className={mobileNavClasses}>
          {['/', '/features', '/photos', '/gettingaround', '/rates'].map((path, index) => (
            <NavLink
              key={index}
              to={path}
              className={({ isActive }) => linkClass(isActive)}
              onClick={() => {
                setOpen(false);
                window.scrollTo(0, 0);
              }}
            >
              {['Home', 'Features', 'Photos', 'Getting Around', 'Booking and Contact Info'][index]}
            </NavLink>
          ))}
        </ul>
      </nav>
    </header>
  );
}

export default React.memo(Nav);