import { useState, useEffect } from "react";
import { useLoading } from "./LoadingContext";
import Loading from "./Loading";

function PhotosPage({ apiUrl }) {
    const [images, setImages] = useState(null);
    const { setLoading } = useLoading();

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            try {
                const response = await fetch(`${apiUrl}pages/18`);
                if (!response.ok) {
                    throw new Error('Failed to fetch');
                }
                const data = await response.json();
                setImages(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [apiUrl, setLoading]);

    if (!images) {
        return <Loading />;
    }

    return (
        <div>
            <h1 className="text-center bg-[#232323] text-white p-8 mb-0">Welcome to Our Gallery</h1>
            <ul className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ">
                {images.acf.photos_gallery.map((image, index) => (
                    <li key={index} className="list-none">
                        <img src={image.url} alt={`Gallery image ${index + 1}`} className="w-full h-auto" />
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default PhotosPage;
